.add-file{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.App {
  text-align: center;
  background: #92b0e0;
  height: 100%;
  padding-left:15%;
  padding-right: 15%;
}

.addproject, .dailytasks {
  display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    /* padding: 20px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    background: #92b0e0;
    max-height: 30vh;
    overflow-y: overlay;
}

.addtask{
  padding:20px;
}

.addproject input[type="text"],
.addproject textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 60%;
}

.addproject button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.addproject button:hover {
  background-color: #0062cc;
}
button , input[type="file"]{
  padding: 5px 10px;
    background-color: #ffffff;
    color: #007bffb0;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
}
.dailytaskscroll{
  
  max-height: 80%;
}
.delete-project button{
  margin:20px;
}
.delete-project-inner{
  background-color: black;
  width: 60%;
  margin:auto;
}

.filelink{
  background-color: #007bff;
  width: 100%;
  color:white;
}

.file-link-th{
  width:100%;
}
.filelink:hover{
  background-color: #9effe7;
  cursor: pointer;
}
.header{
  display: flex;
  margin:auto;
  justify-content: center;
  width: 95%;
    background: #92b0e0;
    font-size: xx-large;
    color: #ffffffd4;
    padding: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}
input, select, textarea{
  border-radius: 5px;
  padding:5px;
  margin-right: 5px;
}

.project-list {
  display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.project-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.project-list th,
.project-list td {
  padding: 0px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  background: #92b0e0;
    color: rgb(255, 255, 255);
}

.project-list th {
  background: #60a5ec;
    color: white;
  font-weight: bold;
}

.project-list td button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.project-list td button:hover {
  background-color: #0062cc;
}

.project-list table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.project-list table table th,
.project-list table table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.project-list table table th {
  background-color: #f2f2f2;
  font-weight: bold;
  background-color:#60a4eb;
}
.project-name{
  background: #2c445d;
    text-align: center;
    padding: 20px;
    font-size: xxx-large;
    position: relative;
}
.project-name button{
  position: absolute;
    left: 10px;
    top: 10px;
}
.project-name button:nth-of-type(2){
  margin-left: 50px;
}
.project-desc{
  font-style: italic;
    font-weight: 700;
    margin: 40px;
    display: flex;
    color: #000000;

}
.startbtn{
  background-color: #92b0e0;
  margin:0;
}
.status{
  font-size: medium;
  color: coral;
  margin-left:30px; 
  background: white;
  padding:10px 30px 10px 30px;
}
.subhead{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 100%;
}
.task-desc {
  width: 80%; /* Make the task description cell fill the remaining space */
}

.task-name {
  width: 20%; /* Make the task description cell fill the remaining space */
}

.details-cell {
  text-align: right; /* Align the "Details" button to the right */
}
.taskdetails, .delete-project
{
  position: fixed;
  left:0px;
  top:0px;
  width:100vw;
  background-color: #000000d4;
  height:100vh;
  color: #fff;
  z-index: 9;
}
.task-details-content{
  width:50%;
  margin:auto;
  text-align: center;
  background-color: #92b0e0;
  padding:30px;
  overflow-y: scroll;
  height: 60vh;
  margin-top: 20vh;
  border-color: white;
    border-width: 20px;
    border-style: solid;
}
.task-details-content button {
  padding: 5px 10px;
  background-color: #ffffff;
  color: #007bffb0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  
}
.task-details-content button:hover {
 background-color: #4c4bad;
 color: #ffffff;
}

.task-detail-close-btn{
  display: flex;
}
.edit-task-details{

  padding:20px;
  margin-top:20px;
}
.edit-task-details textarea{
  width:100%;
  height: 200px;
}

.task-detail-close-btn{
  position:fixed;
  top:40px;
  left:40px;
}
.task-status{
  position:fixed;
  top:40px;
  margin:auto;
}

@media (orientation: portrait)  {
  .header{
    font-size: x-large;
    max-width: 80vw;
  }

  .subhead{
    flex-direction: column;
  }

  .App{
    padding:0%;
  }
  .addproject, .dailytasks{
    max-width: 100%;
    margin:0px;
  }
  .dailytasks{
    align-items: normal;
    scrollbar-color: black;
  }
  .header{
    margin-top: -30px;
    width: 100vw;
  }
  .project-list{
    align-items:self-start;
  }
  .project-list th{
    max-width: 100vw;
  }
  .task-name{
    width: 10vw;
      max-width: 10vw;
  }
  .task-desc{
    max-width: 50vw;
    padding-left: 8px;
  }
  .details-cell{
    max-width: 10vw;
  }
  .task-progress{
    max-width: 2vw;
    writing-mode: vertical-lr;
    transform: translate(0%, 0%);
    font-size: xx-small;
  }
  .project-name{
    font-size: xx-large;
  }
  .task-details-content{
    width: auto;
  }

}

